.home-elements {
  padding-top: 6rem;
  padding-left: 4rem;
  text-align: left;
}
.home-elements-s {
  padding-top: 2rem;
  padding-left: 4rem;
  text-align: left;
}
.logo{
  padding-top: 10%;
  width: 60%;
  z-index: 1;
  margin-left: 3rem;
}
.floating{
  z-index: 20;
}
.p-text{
  display: flexbox;
  align-items: baseline;
  font-size: medium;
  margin-top: 2em;
}
.small {
  font-size: medium;
  margin: 1em 2em 0 0;
}
.left-s{
  margin-left: 3rem;
}
.right-s{
  margin-right: 3rem;
}
.contact-button{
  transition: 2s background-color;
}
.contact-button:hover {
  background-color: #45337D;
  transition: ease-in-out;
}
.text{
  padding-right: 5rem;
  padding-left: 2rem;
}
#next1{
  padding-left: 5rem;
}
.bar{
  float: right;
  width: 100vw;
}
.line1{
  max-width: 70vw;
}
.line3{
  padding-top: 3rem;
  padding-right: 1rem;
  padding-left: 60vw;
}
.text-dargrey{
  color: #272726;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 4rem;
  height: 1em;
  letter-spacing: 8px;
}
.text-grey{
  color: #959499;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 4rem;
  letter-spacing: 8px;
}
.text-white{
  color: #D3D4D8;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 4rem;
  height: 1em;
  letter-spacing: 8px;
}
.up-arrow{
  transform: rotate(180deg);
}
.space{
  padding-top: 10rem;
  padding-bottom: 10rem;
}
.space-bottom{
  padding-bottom: 5rem;
}

.comic-text{
  width: 35rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  
}

/* Three image containers (use 25% for four, and 50% for two, etc) */
.column {
  float: left;
  width: 33.33%;
  padding: 5px;
}
/* Two column containers*/
.twocolumn {
  float: left;
  width: 50%;
}

/* Clear floats after image containers */
.row::after {
  content: "";
  clear: both;
  display: table;
}

.twocolumn-s {
  display: flex;
  width: 50%;
}
.comic-text-s{
  padding: 2rem;  
}

.image {
  display: block;
  margin-left: -2rem;
  margin-right: auto;
  width: 100%;
}

.text-background{
  background: rgb(0,0,0);
  background: linear-gradient(90deg, 
      rgba(0,0,0,1) 14%, 
      rgba(39,27,69,1) 34%,
      rgba(69,51,125,1) 42%,
      rgba(98,77,139,1) 50%,
      rgba(69,51,125,1) 58%,
      rgba(39,27,69,1) 65%, 
      rgba(0,0,0,1) 86%);
}
#work{
  padding-top: 10%;
}
.padding{
  margin-left: 0.5rem;
}
.background-image{
  background-image: url("../../assets/Line\ 3.png");
  background-repeat: no-repeat;
  background-size: 80% 60%;
  background-position: center;
}
.floating{
  position: fixed;
  top: 100%;
  left: 65%;
  z-index: 3;
  margin-top: -100px; /* Negative half of height. */
  margin-left: -250px; /* Negative half of width. */
}

#hide {
  position: fixed;
  top: 90%;
  left: 50%;
  z-index: 3;
}
.spacer{
  margin-bottom: 30vh;
}
.text-sml{
  padding: 2rem;
}
.images-sml{
  width: 80%;
}
.pgf{
  margin-top: 20%;
  
}
/* * {
  outline: 1px solid violet;
} */