@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

body {
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.container{
    width: 100vw;
    overflow: hidden;
}
.background-image{
    background-image: url("../../assets/Line\ 3.png");
    background-repeat: no-repeat;
    background-size: 80% 60%;
    background-position: center;
}
.services-nav{
    padding-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.services-button{
    transition: 2s background-color;
}
.services-button:hover{
    background-color: #45337D;
    transition: ease-in-out;
}
.services-button-active{
    background-color: #45337D !important;
    transition: 2s background-color;
    width: 100%;
}
.services-button{
        width: 100%;
    }
.title {
    display: flex;
    align-items: left;
    justify-content: left;
    margin-bottom: -2rem;
    margin-top: -7%;

}
.title-strong {
    color: #45337D;
    padding-left: 0.5em;
}
.line {
    min-width: 100%;
    max-height: .2rem;
    /* margin-top: -2rem;
    margin-bottom: 2rem; */
}
.dinamic-text{
    font-size: 1em;
    text-align: left !important;
}
.title-s{
    font-size: 1.2rem;
    text-align: left;
    color: #959499;
}
.services-nav-s{
    display: flex;
    align-items: center;
    justify-content: center;
}
.hidden{
    visibility: hidden;
}
.left-arrow{
    margin-left: 2rem;
    margin-right: 2rem;
    width: 1rem;
}
.right-arrow{
    margin-right: 2rem;
    width: 1rem;
}
.line-s{
    padding-top: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    align-self: 'center';
    display: flex;
    width: 100vw; 
    align-items: center;
    justify-content: center;
}
/* * {
    outline: 1px solid rgb(177, 0, 38, 0.5);
} */