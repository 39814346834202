/* unvisited link */
a:link {
    color: #d3d4d8;
}

/* visited link */
a:visited {
    color: 624D8B;
}

/* mouse over link */
a:hover {
    color: #271B45;
}

/* selected link */
a:active {
    color: #959499;
}